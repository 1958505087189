.contact-container {
    display: flex;
    justify-content: center;
  }
  .contact-form {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contact-form::before {
    content: "";
    background-image: url("https://images.unsplash.com/photo-1528747045269-390fe33c19f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
    width: 100%;
    height: 100%;
    background-size: cover;
    min-width: 100vw;
    background-position: center top;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .contact-form h1 {
    font-size: 2.4rem;
    margin: 1rem;
  }
  .contact-form p {
    font-size: 1.4rem;
    text-align: center;
  }
  
  .form-element {
    display: flex;
    flex-direction: column;
    margin: 1.4rem;
  }
  .login-form-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .btn-1 {
    align-self: center;
  }
  .form-label {
    color: #fff;
    margin: 10px;
  }
  .input-box {
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid #ffffff;
    color: #fff;
  }

  @media screen and (max-width:920px){
    .form-element {
        display: flex;
        flex-direction: column;
        margin-right: 1.4rem;
        padding:10px;
      }
      .input-box {
        margin-left: 1.4rem;
        margin-right:30px;
        width:70vw
      }
      .form-label {
        color: #fff;
        font-size: 16px;
      }
      .contact-form::before {
        content: "";
        background-image: url("https://images.unsplash.com/photo-1615840287214-7ff58936c4cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80");
        background-size: cover;
        background-position: center top;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      .login-form-card {
        margin-left:34px;
      }
  }