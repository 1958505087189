.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  }
  
  .image-stack {
    display: flex;
    flex-direction: column;
  }
  
  .about .left {
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 350px;
  }
  
  .about .left p {
    margin: 1.2rem 0;
    font-size: 14px;
  }
  
  .about .left h1 {
    margin: 3rem 0;
  }
  
  .about .right {
    max-width: 700px;
  }
  
  .image-stack img {
    position: relative;
  }
  
  .about .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    text-align: center;
  }
  
  .right .img {
    max-width: 90%;
    border: 1px solid #333;
  }
  
  .right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 20%;
    z-index: 5;
  }
  
  .right .bottom {
    grid-column: 4 / span 8;
    grid-row: 1;
    z-index: 4;
  }
  @media screen and (max-width:920px){
    .right{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1.8rem;
      
    }
  }