.bg-mask {
    width: 100%;
    height: 100vh;
    position: relative;
  }
  .home-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 1;
  }
  
  .bg-mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.8;
  }
  
  .home-main {
    height: 100%;
    width: 100%;
  }
  
  .home-main .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
  }
  
  .home-main .content p {
    font-size: 1.4rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  
  .home-main .content h1 {
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
  }
  .content .btn {
    margin: 1.2rem 0.5rem;
  }
  @media screen and (max-width: 640px) {
    .home-main .content p {
      font-size: 1.4rem;
    }
  
    .home-main .content h1 {
      font-size: 3rem;
    }
  }

  .home-form{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("https://images.unsplash.com/photo-1615840287214-7ff58936c4cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80");
    background-size: cover;
  }
  .home-form-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  