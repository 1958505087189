@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
}
body {
  background-color: #000000;
}
h1,
h2,
h3,
h4,
h6,
p,
a {
  color: #ffffff;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}
.btn-1 {
  background-color: rgb(248, 217, 15);
  color: #222;
}
.btn-2 {
  background-color: transparent;
  color: #fff;
}
.btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.arrow {
  &::after {
      display: inline-block;
      padding-left: 8px;
      content: "\0279E";
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
  }

  &:hover {


      &::after {
          -webkit-transform: translateX(4px);
          -moz-transform: translateX(4px);
          -ms-transform: translateX(4px);
          -o-transform: translateX(4px);
          transform: translateX(4px);
      }
  }
}

