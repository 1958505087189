.container-main-projects {
    display: flex;
    flex-direction: column;
  }
  .projects {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .projects::before {
    content: "";
    background-image: url("https://images.unsplash.com/photo-1521185496955-15097b20c5fe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1950&q=80");
    width: 100vw;
    height: 100%;
    background-size: cover;
    background-position: center top;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .projects h1 {
    font-size: 2.4rem;
    margin: 1rem;
  }
  .projects p {
    font-size: 1.4rem;
    text-align: center;
  }
  .headings {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }
  .button {
    padding: 10px 20px;
    text-align: center;
    border-radius: 6px;
    transition: 0.3s;
    text-transform: capitalize;
  }
  
  .thumbnail {
    width: 360px;
    height: 240px;
  }
  
  .slider-container {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .project-title {
    text-align: center;
    padding: 4rem 0 2rem 0;
    font-size: 20px;
    width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .project-container {
    max-width: 1140px;
    margin: auto;
  }
  .project-card {
    background: #1a1919;
    padding: 1.2rem 1rem;
    margin-left: 30px;
  }
  .project-btn {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    margin: 1rem;
  }
  .project-btn .btn {
    padding: 0.5rem 1rem;
  }
  .project-main-heading {
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    margin: 1rem;
  }
  .p-icons {
    font-size: 35px;
  }
  @media screen and (max-width: 640px) {
    .projects h1 {
      font-size: 20px;
    }
    .projects p {
      font-size: 1.2rem;
      text-align: center;
    }
    .project-container {
      max-width: 80%;
      margin: auto;
    }
    .thumbnail {
      width: 180px;
      height: 60px;
      align-self: center;
      margin-left:38px
    }
    .project-btn {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;
      margin-right: 28px;
    }
    .button {
      margin: 1rem;
    }
    .project-title {
      width: 240px;
    }
    .project-main-heading {
      margin: 1rem;
    }
    .p-icons {
      font-size: 18px;
    }
    .project-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .container-main-projects{
      width:100vw;
      min-width: 100vw;
    }
  }
  